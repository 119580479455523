<template>
  <svg class="customIcon">
    <use :xlink:href="`${icons}#${icon}`" />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      icons: require('@/assets/icons.svg'),
    };
  }
};
</script>

<style lang="scss" scoped>
.customIcon {
  height: 100%;
  width: 100%;
}
</style>
