<template>
  <div class="measurementSlider">
    <div class="measurementSlider__header">
      <div class="measurementSlider__label">
        <span class="measurementSlider__labelIcon">
          <slot />
        </span>

        {{ label }}
      </div>

      <div class="measurementSlider__value">
        <span
          v-if="!showManualInput"
          v-on:click="toggleManualInput(true)"
        >
          {{ modelValue }} {{ unit }}
        </span>

        <input
          v-if="showManualInput"
          v-bind:value="modelValue"
          ref="input"
          type="number"
          class="measurementSlider__input"
          v-on:blur="toggleManualInput(false)"
          v-on:keyup.enter="toggleManualInput(false)"
          v-on:change="onManualInputChange($event)"
        />
      </div>
    </div>

    <div
      ref="ruler"
      class="measurementSlider__ruler"
    >
      <div
        ref="slider"
        class="measurementSlider__rulerSlider"
      >
        <div
          ref="current"
          class="measurementSlider__currentMarker"
        />
      </div>

      <div
        v-if="!hasBeenDragged"
        class="measurementSlider__hint"
      >
        <font-awesome-icon
          icon="left-right"
          class="measurementSlider__hintIcon"
        />

        {{ $t('bodyMeasurementsModal.measurementSliderLabel') }}
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },

    unit: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showManualInput: false,
      hasBeenDragged: false,
      mouseX: 0,
    };
  },

  watch: {
    modelValue() {
      this.setSliderPosition();
    },
  },

  mounted() {
    const { ruler, slider } = this.$refs;
    const rulerImageWidth = 100; // ~@/assets/ruler-pattern.png
    const sliderWidth = (((this.max - this.min) * 10) + ruler.offsetWidth);
    // make sure the slider width is a multiple of rulerImageWidth to make it center nicely
    const adjustedSliderWidth = (sliderWidth + (rulerImageWidth - (sliderWidth % rulerImageWidth)));

    slider.style.width = `${adjustedSliderWidth}px`;

    nextTick(() => {
      this.setSliderPosition();
      this.setCurrentMarkerPosition();
    });

    slider.addEventListener('mousedown', this.onStartDrag);
    slider.addEventListener('touchstart', this.onStartDrag);
  },

  methods: {
    toggleManualInput(bool) {
      this.showManualInput = bool;

      if (this.showManualInput) {
        nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },

    onStartDrag(event) {
      event.preventDefault();

      this.mouseX = this.getOffsetX(event);
      this.hasBeenDragged = true;

      event.target.addEventListener('mouseup', this.onStopDrag);
      event.target.addEventListener('mousemove', this.onDrag);
      event.target.addEventListener('touchend', this.onStopDrag);
      event.target.addEventListener('touchmove', this.onDrag);
    },

    onStopDrag(event) {
      event.target.removeEventListener('mouseup', this.onStopDrag);
      event.target.removeEventListener('mousemove', this.onDrag);
      event.target.removeEventListener('touchend', this.onStopDrag);
      event.target.removeEventListener('touchmove', this.onDrag);
    },

    onDrag(event) {
      const { ruler, slider } = this.$refs;
      const deltaX = Math.round(this.mouseX - this.getOffsetX(event));
      const currentRulerPosition = ruler.getBoundingClientRect();
      const currentSliderPosition = event.target.getBoundingClientRect();
      const sliderLeftOffset = currentSliderPosition.left - currentRulerPosition.left;
      const newPosition = sliderLeftOffset - deltaX;

      // FIXME on drag mouse out stop dragging

      if (newPosition <= 0 && newPosition >= -event.target.offsetWidth + ruler.offsetWidth) {
        const value = parseFloat((this.modelValue + (deltaX * 0.1)).toFixed(1));

        slider.style.left = `${newPosition}px`;

        if (value >= this.min && value <= this.max) {
          this.$emit('update:modelValue', value);
        }
      }
    },

    setSliderPosition() {
      // set the slider position based on the delta value
      // between the middle of min and max and the model value
      const { ruler, slider } = this.$refs;
      const centerPosition = ((slider.offsetWidth - ruler.offsetWidth) / 2) * -1;
      const deltaValue = ((this.max - this.min) / 2) - this.modelValue;
      const newPosition = centerPosition + (deltaValue * 10);

      slider.style.left = `${newPosition}px`;
    },

    setCurrentMarkerPosition() {
      const { slider, current } = this.$refs;
      const centerPosition = (slider.offsetWidth / 2);
      const deltaValue = ((this.max - this.min) / 2) - this.modelValue;
      const newPosition = centerPosition - (deltaValue * 10);

      current.style.left = `${newPosition}px`;
    },

    getOffsetX(event) {
      if (!event.type.includes('touch')) {
        return event.offsetX;
      }

      const { target, targetTouches } = event;
      const rect = target.getBoundingClientRect();

      return targetTouches[0].pageX - rect.left;
    },

    onManualInputChange(event) {
      const value = parseFloat(event.target.value);

      if (value >= this.min && value <= this.max) {
        this.$emit('update:modelValue', value);
      }

      nextTick(this.setSliderPosition);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.measurementSlider__header {
  position: relative;
  margin: 0 0 rem(16px) 0;
  width: 100%;
}

.measurementSlider__label {
  @include paragraph--bold;
  display: flex;
  align-items: center;
}

.measurementSlider__labelIcon {
  margin: 0 rem(12px) 0 0;
  font-size: rem(24px);
  color: $color-yellow;
}

.measurementSlider__value {
  @include paragraph--bold;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  color: $color-blue;
}

.measurementSlider__input {
  @include paragraph--bold;
  max-width: 100px;
  text-align: center;
  color: $color-blue;
}

.measurementSlider__ruler {
  position: relative;
  height: 56px;
  background: #f0f2f5;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 1px);
    width: 2px;
    height: 100%;
    background: $color-blue;
  }
}

.measurementSlider__rulerSlider {
  position: absolute;
  top: 0;
  height: 100%;
  background: transparent url('~@/assets/ruler-pattern.png') repeat-x 0 100%;
  cursor: ew-resize;
}

.measurementSlider__currentMarker {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background: red;
  transform: translateX(-1px);
}

.measurementSlider__hint {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #aaa;
  pointer-events: none;
}

.measurementSlider__hintIcon {
  margin: 0 rem(8px) 0 0;
}
</style>