<template>
  <div>
    <base-loader
      v-if="isFetchingData"
      modifiers="center"
    />

    <div
      v-if="!isFetchingData"
      class="bodyMeasurementsModal"
    >
      <base-heading
        type="2"
        modifiers="center"
      >
        {{ $t('bodyMeasurementsModal.title') }}
      </base-heading>

      <p class="bodyMeasurementsModal__body">
        {{ $t('bodyMeasurementsModal.body') }}
      </p>

      <measurement-slider
        unit="kg"
        v-bind:min="0"
        v-bind:max="250"
        v-bind:label="$t('bodyMeasurementsModal.weightLabel')"
        v-model="weight"
        class="bodyMeasurementsModal__slider"
      >
        <font-awesome-icon
          icon="weight-scale"
          class="bodyMeasurementsModal__sliderIcon"
        />
      </measurement-slider>

      <measurement-slider
        unit="kg"
        v-bind:min="0"
        v-bind:max="250"
        v-bind:label="$t('bodyMeasurementsModal.targetWeightLabel')"
        v-model="targetWeight"
        class="bodyMeasurementsModal__slider"
      >
        <font-awesome-icon
          icon="bullseye-arrow"
          class="bodyMeasurementsModal__sliderIcon"
        />
      </measurement-slider>

      <measurement-slider
        unit="cm"
        v-bind:min="0"
        v-bind:max="250"
        v-bind:label="$t('bodyMeasurementsModal.waistLabel')"
        v-model="waistSize"
        class="bodyMeasurementsModal__slider"
      >
        <custom-icon
          v-bind:icon="`waist-${iconGender}`"
          class="bodyMeasurementsModal__sliderCustomIcon"
        />
      </measurement-slider>

      <measurement-slider
        unit="cm"
        v-bind:min="0"
        v-bind:max="250"
        v-bind:label="$t('bodyMeasurementsModal.hipLabel')"
        v-model="hipSize"
        class="bodyMeasurementsModal__slider"
      >
        <custom-icon
          v-bind:icon="`hip-${iconGender}`"
          class="bodyMeasurementsModal__sliderCustomIcon"
        />
      </measurement-slider>

      <base-alert
        v-if="savingError"
        class="bodyMeasurementsModal__alert"
      >
        {{ savingError }}
      </base-alert>

      <div
      v-if="showMedicalConsent"
      class="bodyMeasurementsModal__consentForm"
    >
      <checkbox-toggle v-model="medical_consent" />

      <div class="bodyMeasurementsModal__consentLabel">
        {{ $t('bodyMeasurementsModal.medicalConsentLabel') }}
      </div>
    </div>

      <div
        v-bind:class="{
          'bodyMeasurementsModal__buttons': true,
          'bodyMeasurementsModal__buttons--two': member.integrations.medisana,
        }"
      >
        <base-button
          modifiers="primary block fullWidth"
          v-bind:show-spinner="saving"
          v-bind:disabled="!medical_consent || !!saving"
          loading-text="Aan het opslaan"
          class="bodyMeasurementsModal__button"
          v-on:click="onSaveBodyMeasurements"
        >
          {{ $t('bodyMeasurementsModal.saveButtonLabel') }}
        </base-button>

        <base-button
          v-if="member.integrations.medisana"
          modifiers="secondary block fullWidth"
          v-bind:show-spinner="spinner"
          v-bind:disabled="!medical_consent"
          loading-text="Ophalen gegevens"
          class="bodyMeasurementsModal__button"
          v-on:click="onSyncMedisanaData"
        >
          <div class="button__icon">
            <font-awesome-icon v-bind:icon="['fa', 'arrows-rotate']" />
          </div>

          {{ $t('bodyMeasurementsModal.medisanaSyncButtonLabel') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseLoader from '@/components/BaseLoader';
import BaseAlert from '@/components/BaseAlert';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import MeasurementSlider from '@/components/MeasurementSlider';
import CheckboxToggle from '@/components/CheckboxToggle';
import CustomIcon from '@/components/CustomIcon';

export default {
  components: {
    BaseLoader,
    BaseAlert,
    BaseHeading,
    BaseButton,
    MeasurementSlider,
    CheckboxToggle,
    CustomIcon,
  },

  data() {
    return {
      spinner: false,
      isFetchingData: true,
      saving: false,
      error: false,
      savingError: '',
      weight: 70,
      targetWeight: 70,
      waistSize: 80,
      hipSize: 80,
      showMedicalConsent: false,
    };
  },

  computed: {
    ...mapState('member', ['member', 'profile', 'body', 'privacySettings']),

    medical_consent: {
      get() {
        return this.privacySettings.medical_consent;
      },
      async set(value) {
        await this.updatePrivacySettings({ medical_consent: value })
          .catch(() => this.medical_consent = !value);
      },
    },

    iconGender() {
      if (this.profile.gender === 'f') {
        return 'woman';
      }

      return 'man';
    },
  },

  async created() {
    try {
      await this.fetchProfile();

      this.medicalConsent = this.member.medical_consent;
      this.showMedicalConsent = !this.medical_consent;
    } catch (error) {
      //
    }

    try {
      await this.fetchBody({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      });

      const numMeasurements = this.body.history.length;
      const recentMeasurements = this.body.history[numMeasurements - 1] || {};
      const { weight, waist, hip } = recentMeasurements;
      const { target_weight } = this.body;

      this.weight = weight || this.weight;
      this.targetWeight = target_weight || this.targetWeight;
      this.waistSize = waist || this.waistSize;
      this.hipSize = hip || this.hipSize;
    } catch(error) {
      //
    }

    this.isFetchingData = false;
  },

  methods: {
    ...mapActions('member', [
      'fetchProfile',
      'fetchBody',
      'updatePrivacySettings',
      'updateBody',
      'medisanaSync',
    ]),

    async onSyncMedisanaData() {
      this.spinner = true;

      try {
        await this.medisanaSync();
      } catch(error) {
        this.savingError = this.$t('common.oopsTryAgain');
      } finally {
        this.spinner = false;

        if (!this.savingError) {
          this.close();
        }
      }
    },

    async onSaveBodyMeasurements() {
      this.saving = true;

      try {
        await this.updateBody({
          target_weight: this.targetWeight,
          history: {
            weight: this.weight,
            waist: this.waistSize,
            hip: this.hipSize,
          },
        });
      } catch (error) {
        this.savingError = error ? error.message : this.$t('common.oopsTryAgain');
      } finally {
        this.saving = false;

        if (!this.savingError) {
          this.close();
        }
      }
    },

    close() {
      this.$router.push({ name: 'aboutyouBody' }).catch(() => {});
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.bodyMeasurementsModal__body {
  @include small;
  margin: 0 auto rem(32px) auto;
  max-width: 450px;
  text-align: center;
}

.bodyMeasurementsModal__slider {
  margin: 0 0 rem(48px) 0;
}

.bodyMeasurementsModal__sliderCustomIcon {
  width: rem(32px);
  height: rem(32px);
}

.bodyMeasurementsModal__alert {
  margin: 0 0 rem(20px) 0;
}

.bodyMeasurementsModal__consentForm {
  display: flex;
  margin: 0 0 rem(20px) 0;
}

.bodyMeasurementsModal__consentLabel {
  @include label;
  margin: 0 0 0 rem(12px);
}

.bodyMeasurementsModal__buttons {
  width: 100%;

  &--two {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.bodyMeasurementsModal__button {
  .bodyMeasurementsModal__buttons--two & {
    &:first-child {
      margin: 0 rem(4px) 0 0;
    }

    &:last-child {
      margin: 0 0 0 rem(4px);
    }
  }
}
</style>
