<template>
  <div v-bind:class="{
    [blockName]: true,
    [modifierClasses]: true,
  }">
    <div class="loaderSpinner__block">
      <div
        v-for="n in 12"
        v-bind:key="n"
        class="loaderSpinner__element"
      />
    </div>
  </div>
</template>

<script>
import modifiers from '@/mixins/modifiers';

export default {
  mixins: [
    modifiers([
      'tiny',
      'center',
    ]),
  ],

  data() {
    return {
      blockName: 'loaderSpinner',
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.loaderSpinner {
  display: inline-block;

  &--center {
    display: block;
    text-align: center;
  }

  &--tiny {
    transform: scale(0.5);
  }
}

.loaderSpinner__block {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  .loaderSpinner--tiny & {
    width: 20px;
    height: 20px;
  }
}

.loaderSpinner__element {
  transform-origin: 20px 20px;
  animation: loaderSpinner 1.2s linear infinite;
}

.loaderSpinner__element {
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 18px;
    width: 3px;
    height: 9px;
    border-radius: 20%;
    background: $color-grey;
  }

  @for $i from 0 through 11 {
    &:nth-child(#{$i + 1}) {
      transform: rotate(#{$i * 30}deg); // 0 -> 330
      animation-delay: -#{1.1 - ($i * 0.1)}s; // -1.1 -> 0
    }
  }
}

@keyframes loaderSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>