<template>
  <label class="checkboxToggle">
    <input
      type="checkbox"
      class="checkboxToggle__input"
      v-bind:value="modelValue"
      v-bind:checked="shouldbeChecked"
      v-bind:id="id"
      v-on:change="updateInput"
    >

    <span class="checkboxToggle__element" />
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },

    modelValue: {
      type: Boolean,
    },

    trueValue: {
      default: true,
    },

    falseValue: {
      default: false,
    },
  },

  computed: {
    shouldbeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.modelValue);
      }

      return this.modelValue === this.trueValue;
    },
  },

  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.modelValue);
        } else {
          newValue.splice(newValue.indexOf(this.modelValue), 1);
        }

        this.$emit('update:modelValue', newValue);
      } else {
        this.$emit('update:modelValue', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.checkboxToggle__element {
  display: flex;
  position: relative;
  width: 36px;
  height: 20px;
  background: #ddd;
  border-radius: 18px;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 18px;
    width: 18px;
    background: $color-white;
    border-radius: 50%;
    transition: all 0.2s;
  }
}

.checkboxToggle__input {
  display: none;
}

.checkboxToggle__input:checked + .checkboxToggle__element {
  background: $color-mint--dark;

  &:before {
    left: 17px;
  }
}
</style>